import $ from 'jquery';
import {gpt_ip} from './environment.js'

export function getModuleModel(module_id) {
	return $.ajax({
		url: gpt_ip+"/gpt/getModuleModel?module_id="+module_id,
		type: "GET"
	})
}

export function getModelModule(module_id) {
	return $.ajax({
		url: gpt_ip+"/gpt/getModelModule?module_id="+module_id,
		type: "GET"
	})
}

export function get_qrCode(qrCodeType,qrCodeValue) {
	return $.ajax({
		url: gpt_ip+"/user/getQrCode?qrCodeType="+qrCodeType+"&qrCodeValue="+qrCodeValue,
		type: "POST"
	})
}

export function is_login_suc(qrCodeValue) {
	return $.ajax({
		url: gpt_ip+"/user/isLoginSuc?qrCodeValue="+qrCodeValue,
		type: "POST"
	})
}

export function getChatList(userToken) {
	console.log(userToken)
	return $.ajax({
		url: gpt_ip+"/chatListMes/getChatList?userToken="+userToken,
		type: "POST"
	})
}

export function getCurChatMes(chatListId) {
	return $.ajax({
		url: gpt_ip+"/chatListMes/getCurChatMes?chatListId="+chatListId,
		type: "GET"
	})
}

export function gptsSearch(text) {
	return $.ajax({
		url: gpt_ip+"/gpt/gptsSearch?text="+text,
		type: "GET"
	})
}


// export function logSucAftVerify(userToken) {
// 	return $.ajax({
// 		url: gpt_ip+"/user/logSucAftVerify?userToken="+userToken,
// 		type: "GET"
// 	})
// }

export function newMesList(userToken,mesListId) {
	return $.ajax({
		url: gpt_ip+"/gpt/newMesList?userToken="+userToken+"&mesListId="+mesListId,
		type: "GET"
	})
}


export function delMesList(mesListId) {
	return $.ajax({
		url: gpt_ip+"/gpt/delMesList?mesListId="+mesListId,
		type: "GET"
	})
}

export function savMesListTitle(mesListId,title) {
	return $.ajax({
		url: gpt_ip+"/gpt/savMesListTitle?mesListId="+mesListId+"&title="+title,
		type: "GET"
	})
}


export function create_uuid(){
	function generateUUID() {
		return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = Math.random() * 16 | 0,
			v = c === 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
		});
	}
	
	function removeHyphens(uuid) {
		return uuid.replace(/-/g, '');
	}
	
	// 生成UUID并去掉破折号
	var uuid = generateUUID();
	var uuidWithoutHyphens = removeHyphens(uuid);
	return uuidWithoutHyphens;
}

